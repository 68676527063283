import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import blockFactory from '@utils/blockFactory';
import getLocalizedData from '@utils/localize';
import { TranslationContext } from '@utils/useTranslations';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Navigation from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';

export const query = graphql`
  query StoryQuery($id: String!) {
    story: sanityStory(id: { eq: $id }) {
      published_vi
      published_zh_Hans
      title {
        en
        vi
        zh_Hans
        _type
      }
      slug {
        current
      }
      heroImageExtended {
        credit
        link
        videoUrl
        route {
          title {
            _type
            en
            vi
            zh_Hans
          }
          slug {
            current
          }
        }
        altText {
          en
          vi
          zh_Hans
          _type
        }
        image  {
          asset {
            fluid(
              maxWidth: 800,
              sizes: "(max-width: 600px) 100vw, 50vw"
              ){
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      publishedAt
      excerpt {
        en
        vi
        zh_Hans
        _type
      }
      category {
        title {
          en
          vi
          zh_Hans
          _type
        }
      }
      showChat
      isExcludedFromSearch
      _updatedAt
      _rawContent(resolveReferences: { maxDepth: 12 })
      eventData {
        cta {
          label {
            en
            vi
            zh_Hans
            _type
          }
          link
          route {
            slug {
              current
            }
          }
          theme
        }
        date
        location
        time
      }
    }
  }
`;

const BlogStoryTemplate = (props) => {
  const { data: rawData } = props;
  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `story.published_${intl.locale}`) ? intl.locale : 'en',
  );
  const story = data && data.story;

  const translationContext = {
    vi: get(rawData, 'story.published_vi'),
    zh_Hans: get(rawData, 'story.published_zh_Hans'),
  };

  const heroProps = {
    headingAlignment: 'center',
  };
  if (get(story, 'heroImageExtended.image.asset')) {
    heroProps.headingAlignment = 'right';
    heroProps.imagesExtended = [story.heroImageExtended];
  }

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={props.banner} navItems={props.navItems} />
        <main>
          <SEO title={story.title}
               image={get(story, 'heroImageExtended.image.asset.fixed')}
               description={get(story, 'excerpt')}
               noIndex={story.isExcludedFromSearch}
          />
          <Hero
            description={story.excerpt}
            heading={story.title}
            category={story.category}
            publishedDate={story.publishedAt}
            eventData={story.eventData}
            updatedDate={story._updatedAt}
            colorTheme="blue"
            {...heroProps}
          />
          <Space sizes={['xsmall', 'small', 'small']} />
          {(story._rawContent || []).map((block, idx) => (
            <Fragment key={idx}>
              {blockFactory(block, null, intl.locale)}
            </Fragment>
          ))}
        </main>
        <Space sizes={['small', 'large']} />
        <Footer showChat={story.showChat} />
      </TranslationContext.Provider>
    </>
  );
};

export default BlogStoryTemplate;
